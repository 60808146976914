import { expectTypeOf } from "vitest";
import { components } from "../api/__generated";

/**
 * Retrieve a role's access to a specific resource based on the ACL.
 * @param role the role to check
 * @param acl the acl of the resource to verify
 * @returns an ACL list equal to the role's permissions on this resource
 */
export function getAclAccessForRole(
    role: components["schemas"]["Role"],
    acl?: components["schemas"]["ACL"] | null
): NonNullable<NonNullable<components["schemas"]["ACL"]["roles"]>[string]> {
    // root role = full access

    if (role.root) {
        return { view: true, modify: true, manage: true };
    }

    // no acl == full access
    if (!acl) {
        return { view: true, modify: true, manage: true };
    }

    // if acl is set but no roles defined == no access
    if (!acl.roles || !Object.keys(acl.roles).length) {
        return { view: false, modify: false, manage: false };
    }

    // acl && role in acl === acl role access
    const roleAcl = acl.roles[role.id];
    if (roleAcl) {
        // defined access
        return roleAcl;
    }

    // acl but the role isn't in it == no access
    return { view: false, modify: false, manage: false };
}

/**
 * Checks if a role has the specified capabilites.
 * @param role the role to check
 * @param caps the desired caps to check for - returns true only if all are satisfied
 * @returns true if all caps are available to the role
 */
export function doesRoleHaveCapabilities(
    role: components["schemas"]["Role"],
    caps: components["schemas"]["Capability"][]
): boolean {
    return role.capabilities.all
        ? true
        : caps.every((c) => role.capabilities.specific.includes(c));
}

export type ResourceStateFull = components["schemas"]["State"] & {
    current: string;
    error?: {
        message?: string;
    };
    health?: components["schemas"]["InstanceState"]["health"];
};

export type RoleAcl = NonNullable<
    NonNullable<components["schemas"]["ACL"]["roles"]>[string]
>;

export type AclResource = {
    state: ResourceStateFull;
    id: string;
    acl?: any | null;
    cluster?: string;
};

export type VerifyFn<T extends AclResource> = (
    resource: T,
    acl: RoleAcl,
    role: components["schemas"]["Role"]
) => Error | undefined;

export function verifyAccess<T extends AclResource>({
    aclResource,
    cluster,
    role,
    verifyFn,
}: {
    aclResource: T;
    cluster?: components["schemas"]["Cluster"];
    role: components["schemas"]["Role"];
    verifyFn: VerifyFn<T>;
}): Error | undefined {
    let acl: RoleAcl = { view: true, modify: true, manage: true };

    if (aclResource.acl) {
        acl = getAclAccessForRole(role, aclResource.acl);
    } else if (aclResource.cluster) {
        if (!cluster) {
            return new Error(
                "resource is part of a cluster, but no cluster was passed"
            );
        }
        const clusterAcl = getAclAccessForRole(role, cluster.acl);

        // If the cluster has modify, it will grant manage access to child
        // cluster manage has no bearing on child ACLs
        acl = { ...clusterAcl, manage: clusterAcl.modify };
    } else {
        acl = { view: true, modify: true, manage: true };
    }

    return verifyFn(aclResource, acl, role);
}

export const readOnlyAccessFn =
    (capability: components["schemas"]["Capability"]): VerifyFn<AclResource> =>
    (aclResource, acl, role) => {
        if (!doesRoleHaveCapabilities(role, [capability])) {
            return new AccessError(
                `missing required capability '${capability}')`
            );
        }

        if (aclResource.state.current === "deleted") {
            return new Error("resource is deleted");
        }

        // If the user has one or the other, they may have access to SOME functionality in the resource
        if (!acl.modify && !acl.manage) {
            return new AccessError("restricted due to access controls");
        }

        return;
    };

export const modifyAccessFn =
    (capability: components["schemas"]["Capability"]): VerifyFn<AclResource> =>
    (aclResource, acl, role) => {
        if (!doesRoleHaveCapabilities(role, [capability])) {
            return new AccessError(
                `missing required capability '${capability}'`
            );
        }

        if (aclResource.state.current === "deleted") {
            return new Error("resource is deleted");
        }

        if (!acl.modify) {
            return new AccessError("restricted due to access controls");
        }

        return;
    };

export const manageAccessFn =
    (capability: components["schemas"]["Capability"]): VerifyFn<AclResource> =>
    (aclResource, acl, role) => {
        if (!doesRoleHaveCapabilities(role, [capability])) {
            return new AccessError(
                `missing required capability '${capability}')`
            );
        }

        if (aclResource.state.current === "deleted") {
            return new Error("resource is deleted");
        }

        if (!acl.manage) {
            return new AccessError("restricted due to access controls");
        }

        return;
    };

export class AccessError extends Error {
    isAcl: boolean = false;

    constructor(message?: string) {
        super(message);
    }

    setCapability(
        cap:
            | components["schemas"]["Capability"]
            | components["schemas"]["Capability"][]
    ) {
        let message = "";

        if (typeof cap === "string") {
            message = `${
                this.message ? this.message + ":" : ""
            } missing ${cap} capability`;
        } else {
            message = `${
                this.message ? this.message + ":" : ""
            } missing ${cap.join(", ")} capabilities`;
        }
        this.message = message;
        return this;
    }

    setAclResource(resource: string) {
        this.message = `${
            this.message ? this.message + ":" : ""
        } restricted due to ${resource} access controls`;
        return this;
    }
}
