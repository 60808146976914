import classNames from "classnames";
import { FormProvider, UseFormReturn } from "react-hook-form";

// We do not care about individual fields here, so any is acceptable in this case
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RhfFormProviderProps = UseFormReturn<any> & {
    children: React.ReactNode;
    onSubmit?: (e: React.FormEvent) => void;
    className?: string;
};

export function RhfFormProvider({
    onSubmit,
    children,
    className,
    ...props
}: RhfFormProviderProps) {
    return (
        <FormProvider {...props}>
            <form
                className={classNames("flex w-full flex-col ", className)}
                onSubmit={onSubmit}
            >
                {children}
            </form>
        </FormProvider>
    );
}
