import { configureStore } from "@reduxjs/toolkit";
import { cycleApi } from "./services/cycle";
import { authApi } from "./services/auth";
import { setupListeners } from "@reduxjs/toolkit/query";
import { appSettingsReducer } from "./modules/settings";
import { hubsReducer } from "./modules/hubs/slice";
import { listenerMiddleware } from "./listeners";
import { authReducer } from "./modules/auth/slice";
import { notificationsReducer } from "./modules/notifications/slice";
import { jobsReducer } from "./modules/jobs/slice";
import { statusApi } from "./services/status";
import { marketingApi } from "./services/marketing";
import { portalApi } from "./services/portal";
import { systemReducer } from "./modules/system/slice";
import { signupApi } from "./services/signup";

export const store = configureStore({
    reducer: {
        appSettings: appSettingsReducer,
        auth: authReducer,
        hubs: hubsReducer,
        notifications: notificationsReducer,
        jobs: jobsReducer,
        system: systemReducer,
        [authApi.reducerPath]: authApi.reducer,
        [signupApi.reducerPath]: signupApi.reducer,
        [portalApi.reducerPath]: portalApi.reducer,
        [cycleApi.reducerPath]: cycleApi.reducer,
        [statusApi.reducerPath]: statusApi.reducer,
        [marketingApi.reducerPath]: marketingApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .prepend(listenerMiddleware.middleware)
            .concat(
                cycleApi.middleware,
                portalApi.middleware,
                authApi.middleware,
                signupApi.middleware,
                statusApi.middleware,
                marketingApi.middleware
            );
    },
    devTools: {
        maxAge: 50,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
