import { Acl } from "~/services/cycle";

export type AclForm = {
    enabled: boolean;
    roleIds: string[];
    roles: { view: boolean; modify: boolean; manage: boolean }[];
};

export function getAclDefaultValues(acl?: Acl | null) {
    if (!acl?.roles) {
        return {
            enabled: false,
            roleIds: [],
            roles: [],
        };
    }

    return {
        enabled: true,
        roleIds: Object.keys(acl.roles),
        roles: Object.values(acl.roles),
    };
}

export function createAclSubmitBody(
    data: AclForm,
    submitType: "create" | "update"
): { acl: Acl | undefined | null } {
    if (!data.enabled) {
        return submitType === "create" ? { acl: null } : { acl: { roles: {} } };
    }
    return {
        acl: {
            roles: data.roleIds.reduce((acc, cur, idx) => {
                return {
                    ...acc,
                    [cur]: data.roles[idx],
                };
            }, {}),
        },
    };
}
