import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    Checkbox,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    SectionDisabledControl,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
    SkeletonButton,
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { capitalizeWord } from "@cycleplatform/core/util";
import { Capability } from "~/services/cycle";
import { AclResource, VerifyFn } from "@cycleplatform/core/modules/acls/util";
import { useVerifyAccess } from "~/modules/access";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { handleSubmitError } from "~/components/forms/util";
import { AccessControlOverlay } from "../buttons";

type DeleteResourceCapsProps = {
    capability: Capability | Capability[];
};

type DeleteResourceAclProps<T extends AclResource> = {
    aclResource: T | undefined;
    verifyFn: VerifyFn<T>;
};

type DeleteResourceAccessProps<T extends AclResource | Capability> =
    T extends AclResource
        ? DeleteResourceAclProps<T>
        : T extends Capability
        ? DeleteResourceCapsProps
        : never;

type DeleteResourceProps<T extends AclResource | Capability> = {
    resourceType: string;
    resourceName?: string;
    onSubmit: (force?: boolean) => Promise<unknown>;
    warningMessage?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    isForceable?: boolean;
    formatResourceName?: (resourceName: string) => string;
    warningPanel?: string;
    accessControl: DeleteResourceAccessProps<T> | undefined;
};

export type DeleteResourceSubmitType = {
    name: string;
    force?: boolean;
};

export function DeleteResource<T extends AclResource | Capability>({
    ...props
}: DeleteResourceProps<T>) {
    return (
        <Panel>
            <PanelTitle title={`Delete ${props.resourceType}`} />

            {props.resourceName ? (
                <DeleteResourceForm {...props} />
            ) : (
                <PanelContent>
                    <SkeletonHeader />
                    <SkeletonText size="lg" />
                    <SkeletonText size="lg" />
                    <SkeletonText size="md" />
                    <div className="flex justify-end">
                        <SkeletonButton size="md" />
                    </div>
                </PanelContent>
            )}
        </Panel>
    );
}

function DeleteResourceForm<T extends AclResource | Capability>({
    formatResourceName = (resourceName) => resourceName.slice(0, 15),
    ...props
}: DeleteResourceProps<T>) {
    const [formError, setFormError] = useState<string>();

    const accessError = useVerifyAccess(
        props.accessControl && "aclResource" in props.accessControl
            ? props.accessControl.aclResource
            : undefined,
        props.accessControl && "verifyFn" in props.accessControl
            ? props.accessControl.verifyFn
            : () => undefined
    );

    const capabilityError = useCapability(
        props.accessControl && "capability" in props.accessControl
            ? props.accessControl.capability
            : []
    );
    const form = useForm<DeleteResourceSubmitType>({
        defaultValues: {
            name: "",
        },
        mode: "onBlur",
        reValidateMode: "onChange",
    });
    const {
        register,
        setError,
        reset,
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    const inputValue = useWatch({ name: "name", control });

    useEffect(() => {
        reset({ name: "", force: undefined });
    }, [props.resourceName]);

    const onDelete = async (data: DeleteResourceSubmitType) => {
        if (!props.resourceName || !isDirty) {
            return;
        }
        if (
            data.name.slice(0, 6) !==
            formatResourceName(props.resourceName).slice(0, 6)
        ) {
            setFormError(`The field must match the ${props.resourceType} name`);
            return;
        }
        return props.onSubmit(data.force).catch(handleSubmitError(setError));
    };

    if (!props.resourceName) {
        return null;
    }

    const DeleteResourceAccessControlOverlay = ({
        children,
    }: {
        children: React.ReactNode;
    }) => {
        if (!props.accessControl) {
            return children;
        }
        if ("capability" in props.accessControl) {
            return (
                <AccessControlOverlay
                    capability={props.accessControl.capability}
                >
                    {children}
                </AccessControlOverlay>
            );
        }

        return (
            <AccessControlOverlay {...props.accessControl}>
                {children}
            </AccessControlOverlay>
        );
    };

    return (
        <RhfFormProvider {...form} onSubmit={(e) => e.preventDefault()}>
            <SectionDisabledControl
                disabled={
                    props.accessControl
                        ? !!accessError || !!capabilityError
                        : false
                }
            >
                <PanelContent>
                    <div className="mb-2">
                        <span>Enter </span>{" "}
                        <span className="text-error max-w-[2rem] overflow-clip whitespace-nowrap font-bold">
                            {formatResourceName(props.resourceName)}
                        </span>{" "}
                        <span>
                            into the box below to delete this{" "}
                            {props.resourceType}.
                        </span>
                    </div>
                    <strong>
                        {props.warningMessage ? (
                            props.warningMessage
                        ) : (
                            <>
                                This will permanently delete this{" "}
                                {props.resourceType}. This action cannot be
                                undone.
                            </>
                        )}
                    </strong>
                    {props.warningPanel ? (
                        <InfoPanel type="warning">
                            {props.warningPanel}
                        </InfoPanel>
                    ) : null}

                    {props.isForceable ? (
                        <div className="mt-2">
                            <RhfFormField
                                label={`Force Delete ${props.resourceType}`}
                                name="force"
                            >
                                <Checkbox {...register("force")} />
                            </RhfFormField>
                        </div>
                    ) : null}
                    {props.resourceName ? (
                        <TextInput
                            autoFocus={props.autoFocus}
                            disabled={props.disabled}
                            className="!my-4 w-full"
                            {...register("name", { ...required() })}
                        />
                    ) : (
                        <SkeletonButton className="my-4" />
                    )}

                    {formError && (
                        <p className="text-error mt-4 text-sm">{formError}</p>
                    )}
                    <PanelFooter className="flex-wrap">
                        <RhfGlobalFormError />
                        <DeleteResourceAccessControlOverlay>
                            <PushAndHoldButton
                                isLoading={isSubmitting}
                                disabled={!isDirty || inputValue.length === 0}
                                type="button"
                                flavor="discard"
                                tooltip={`Hold to Delete ${props.resourceType
                                    .split(" ")
                                    .map((w) => capitalizeWord(w))
                                    .join(" ")}`}
                                onClick={form.handleSubmit(onDelete)}
                                icon={faTrash}
                            >
                                Delete {props.resourceType}
                            </PushAndHoldButton>
                        </DeleteResourceAccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </SectionDisabledControl>
        </RhfFormProvider>
    );
}
