import "./styles/generated.css";
import { RouterProvider } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetTokenMutation } from "./services/auth";
import { APPLICATION_ID } from "./modules/app";
import { useAppDispatch, useAppSelector } from "./hooks";
import { loadAppSettings } from "./modules/settings";
import { $error, $info, $trace, $warn } from "@cycleplatform/core/util/log";
import { SplashScreen } from "./components/layout/SplashScreen";
import {
    setSplashScreen,
    setSplashScreenVisible,
} from "./modules/system/slice";
import { getClusterUrls } from "./services/cluster";
import { router } from "./routes/Routes";
import { useRegisterSW } from "virtual:pwa-register/react";

function App() {
    const [getToken, { isUninitialized }] = useGetTokenMutation({
        fixedCacheKey: "authToken",
    });

    const {
        updateServiceWorker,
        needRefresh: [needRefresh],
    } = useRegisterSW({
        onNeedRefresh() {
            $info("a new version of the portal is now available");
            localStorage.setItem("swNeedRefresh", "true");
        },
        onOfflineReady: () => {
            $info("Cycle portal is now installed and available offline");
        },
        onRegisteredSW: async (_, r) => {
            // if initial load, refresh if there's an update
            $trace("service worker registered");
            try {
                r?.update();
            } catch (e) {
                $warn("error checking for service worker update", e);
            }
        },
        onRegisterError(error) {
            $error("unable to register service worker", error);
        },
    });

    const dispatch = useAppDispatch();
    const splash = useAppSelector((state) => state.system.splashScreen);

    useEffect(() => {
        dispatch(loadAppSettings())
            .unwrap()
            .then(() => $info("Cycle Portal initialized"));
    }, []);

    async function preload() {
        if (needRefresh) {
            $info("upgrading to latest version");
            dispatch(
                setSplashScreen({
                    visible: true,
                    message: "Upgrading To Latest Version...",
                })
            );
            localStorage.removeItem("swNeedRefresh");
            updateServiceWorker();
            return;
        }

        dispatch(
            setSplashScreen({
                visible: true,
                message: "Discovering Cluster...",
            })
        );

        // We can't do anything without the cluster URLs.
        // This enters into a holding pattern on the splash screen until we successfully
        // fetch the cluster urls, retrying every 5 seconds.
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const urls = await getClusterUrls();
            if (urls.fetchedTime !== null) {
                break;
            }
            await new Promise((r) => setTimeout(r, 5000));
        }

        dispatch(
            setSplashScreen({
                visible: true,
                message: "Verifying Authentication...",
            })
        );

        // Attempt to get a token on initial load in case
        // we're already logged in. We only do this once.
        getToken({ application_id: APPLICATION_ID })
            .unwrap()
            .catch(() => {
                //
            })
            .finally(() => {
                dispatch(setSplashScreenVisible(false));
            });
    }

    useEffect(() => {
        if (localStorage.getItem("swNeedRefresh") !== null && !needRefresh) {
            setTimeout(() => {
                // escape hatch to make sure we never get stuck here
                localStorage.removeItem("swNeedRefresh");
                if (isUninitialized) {
                    $trace("starting preload");
                    preload();
                }
            }, 5000);
            return;
        }

        // ensure this only runs once
        if (isUninitialized) {
            $trace("starting preload");
            preload();
        }
    }, [isUninitialized, needRefresh]);

    if (splash.visible) {
        // Placeholder while loading - carryover from the raw html and should
        // match the styling for the most part
        return <SplashScreen message={splash.message} />;
    }

    return <RouterProvider router={router} />;
}

export default App;
